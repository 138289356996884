
import '../assets/css/nds.css';

import React, { Component } from "react"
import Formsy from 'formsy-react';
import { connect } from "react-redux"
import { navigate } from "gatsby"

import Layout from "../components/layout"
import Steps from "../components/ndstep"
import bottomLine from "../images/line.png"
import SEO from "../components/seo"
import * as actionCreators from './../store/actions';
import MyInput from "../components/MyInput"
import MyCheckbox from "../components/MyCheckbox";
import ButtonLoader from "../components/buttonLoader";


class nds4 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canSubmit: true,
      infoError:null,
      issc : false,
      nds4Data:{
        DMS:{ADP:false,RR:false,DealerTrack:false,Advent:false,AutoMate:false,Other:null},
        ADP:{accountFI:null,CMF:null},
        RR:{Store:null,FI:null,userId:null},
        menuSys:null, fiSys:null
      }
    };
  }

  componentDidMount = async () => {
    
    if(!sessionStorage.ndsver || sessionStorage.ndsver!==process.env.ndsver){
      sessionStorage.clear();
      sessionStorage.ndsver=process.env.ndsver;
    } 
    
    if(!sessionStorage.nds4Data){
        sessionStorage.nds4Data = window.btoa(JSON.stringify(this.state.nds4Data))
    } 
    var ls = JSON.parse(window.atob(sessionStorage.nds4Data));
    var st = this.state.nds4Data;
    if(!ls.ADP) ls.ADP = st.ADP;
    if(!ls.RR)  ls.RR = st.RR;

    let issc = false;
    if(sessionStorage.issc === 'yes') {
      issc = true;
    }
    
    this.state.nds4Data = ls;
    this.setState({nds4Data:ls, issc:issc});
  }

  valueChanges = async(val) =>{
    let nds4Data = this.state.nds4Data;
    nds4Data.DMS.Other = val;
    this.setState({nds4Data:nds4Data});
   
  }

  disableButton = () => {
    this.setState({ canSubmit: false });
  }
  enableButton = () => {
    let dms = this.state.nds4Data.DMS;
    let issc = this.state.issc;
    //console.log(dms);
    if(issc){
      var ifany =  false;
      Object.keys(dms).forEach(function(key) {
        if(dms[key]){
          ifany =  true;
        }
      });
      if(ifany){
        this.setState({ canSubmit: true,infoError:"" });
      } else {
        this.setState({ canSubmit: false,infoError:"" });
      }
    } else {
      this.setState({ canSubmit: true,infoError:"" });
    }
    
  }
  submit = (model) => {
    const { showLoader, hideLoader} = this.props;
    showLoader();
    if(model.DMS.Other){
      model.DMS.Other = model.Other;
    }
    //console.log(model);
    sessionStorage.nds4Data = window.btoa(JSON.stringify(model));
    hideLoader();
    if(this.state.issc)
      navigate("/nds5b")
    else
      navigate("/nds5")
  }

  featureChanges = (ele, mythis, value) => {
    let nds4Data = this.state.nds4Data;
    Object.keys(nds4Data.DMS).forEach(function(key) {
      nds4Data.DMS[key] = false;
    });
    //if(value)
    nds4Data.DMS[ele] = value;
    /* else {
      if(ele==='ADP')
        nds4Data.DMS.RR = true;
      else
        nds4Data.DMS.ADP = true;
    } */
    //console.log(nds4Data.DMS.ADP);
    this.setState({ nds4Data: nds4Data })
    this.state.nds4Data = nds4Data;
  }
  
   
  render() {
    const { nds4Data, canSubmit,issc } = this.state;
    const { buttonLoader} = this.props;
   
    return (
      <Layout layoutType="nds" >
      <section className="ndsec">
        <Steps></Steps>
          <div className="custom-container vehinfo">
            <div className="title-container">
              {/* <div className="icon-image">
                <img src={vehicalIcon} alt="icon" />
              </div> */}
              <div className="title-text">
                <h2>DMS TYPE
                  {issc ?
                  <span>*</span>
                  :
                  <small> (Optional)</small>
                  }
                  </h2>
                
              </div>
            </div>

            <div className="body-content">
              
              <Formsy ref="nds2Form" onValidSubmit={this.submit} onValid={this.enableButton} onInvalid={this.disableButton}>
              
              <div className="form-container-inline">
                    
                    <div className="check-box-container nds">
                    <div className="form-check">
                      <MyCheckbox
                        name="DMS.ADP" id="cbx1" labelClass="check-container big round" value={nds4Data.DMS.ADP}
                        label="ADP" checkBoxChange={(e) => this.featureChanges('ADP', this, e)}
                      />
                    </div>

                    <div className="form-check">
                      <MyCheckbox
                        name="DMS.RR" id="cbx2" labelClass="check-container big round" value={nds4Data.DMS.RR}
                        label="R&R" checkBoxChange={(e) => this.featureChanges('RR', this, e)}
                      />
                    </div>

                    <div className="form-check">
                      <MyCheckbox
                        name="DMS.DealerTrack" id="cbx3" labelClass="check-container big round" value={nds4Data.DMS.DealerTrack}
                        label="DealerTrack" checkBoxChange={(e) => this.featureChanges('DealerTrack', this, e)}
                      />
                    </div>

                    <div className="form-check">
                      <MyCheckbox
                        name="DMS.Advent" id="cbx4" labelClass="check-container big round" value={nds4Data.DMS.Advent}
                        label="Advent" checkBoxChange={(e) => this.featureChanges('Advent', this, e)}
                      />
                    </div>
                    <div className="form-check">
                      <MyCheckbox
                        name="DMS.AutoMate" id="cbx5" labelClass="check-container big round" value={nds4Data.DMS.AutoMate}
                        label="AutoMate" checkBoxChange={(e) => this.featureChanges('AutoMate', this, e)}
                      />
                    </div>
                    <div className="form-check">
                      <MyCheckbox
                        name="DMS.Other" id="cbx6" labelClass="check-container big round" value={nds4Data.DMS.Other}
                        label="Other" checkBoxChange={(e) => this.featureChanges('Other', this, e)}
                      />
                    </div>
                    </div>
                </div>

                {nds4Data.DMS.Other?
                <div>
                <h3 className="subhead">OTHER {issc && <span>*</span>}</h3>
                <div className="form-container-inline">
                    <div className="form-left">
                        <div className="form-label-group error">
                        <MyInput required={issc} name="Other" id="Other" placeholder="Other" value={nds4Data.Other} isLabelRequired={false} className="form-control"  />
                        </div>
                    </div>
                </div>
                </div>
                :''}    
                
                {nds4Data.DMS.ADP?
                <div>
                <h3  className="subhead">ADP DEALERS</h3>
                <div className="form-container-inline">
                    <div className="form-left">
                        <div className="form-label-group error">
                        <MyInput required={issc} name="ADP.accountFI" id="accountFI" placeholder="Account-FI" value={nds4Data.ADP.accountFI} isLabelRequired={true} className="form-control" />
                        </div>
                    </div>

                    <div className="form-right">
                        <div className="form-label-group error">
                        <MyInput required={issc} name="ADP.CMF" id="CMF" placeholder="CMF#" value={nds4Data.ADP.CMF} isLabelRequired={true} className="form-control" />
                        </div>
                    </div>
                </div>
                </div>
                :''}

                {nds4Data.DMS.RR?
                <div>
                <h3  className="subhead">{'R&R DEALERS'}</h3>
                <div className="form-container-inline">
                    <div className="form-left">
                        <div className="form-label-group error">
                        <MyInput required={issc} name="RR.Store" id="Store" placeholder="Store#" value={nds4Data.RR.Store} isLabelRequired={true} className="form-control" />
                        </div>
                    </div>

                    <div className="form-right">
                        <div className="form-label-group error">
                        <MyInput required={issc} name="RR.FI" id="FI" placeholder="F&I#" value={nds4Data.RR.FI} isLabelRequired={true} className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="form-container-inline">
                    <div className="form-left">
                        <div className="form-label-group error">
                        <MyInput required={issc} name="RR.userId" id="userId" placeholder="Please setup user ID" value={nds4Data.RR.userId} isLabelRequired={true} className="form-control" />
                        </div>
                    </div>
                </div>
                </div>
                :''}
                
                {issc?
                <>
                <div className="form-container-inline linbrk"></div>

                <div>
                <div className="form-container-inline">
                    <div className="form-left">
                        <div className="form-label-group error">
                        <MyInput name="menuSys" id="menuSys" placeholder="Menu System" value={nds4Data.menuSys} isLabelRequired={true} className="form-control" required />
                        </div>
                    </div>

                    <div className="form-right">
                        <div className="form-label-group error">
                        <MyInput name="fiSys" id="fiSys" placeholder="F&I Reporting System" value={nds4Data.fiSys} isLabelRequired={true} className="form-control" required />
                        </div>
                    </div>
                </div>
                </div>
                </>
                :''}


                <div className="form-container-inline linbrk"></div>

                <div className="btn-container">
                    <button type="submit" disabled={!canSubmit || buttonLoader} className={!canSubmit || buttonLoader ? 'btn disabled wizard-btn creat-btn' : 'btn wizard-btn creat-btn'}>
                        <ButtonLoader isLoading={buttonLoader} label={'Continue'} />
                    </button>
                </div>

              </Formsy>
              <div className="txtright">* Field is required</div>
            </div>
          </div>
        </section>
      </Layout>
      );
  }
}

const mapStateToProps = state => {
  return {
    pageLoader: state.primary.pageLoader,
    buttonLoader: state.primary.buttonLoader
  }
};

const mapDispatchToProps = dispatch => {
  return {
    showLoader: () => dispatch(actionCreators.showLoader()),
    hideLoader: () => dispatch(actionCreators.hideLoader()),
    
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(nds4);